html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 96%;
}
html, body {
    margin: 0;
    height: 100%; 
    min-height: 100%;
}
body {
    margin: 0;
    padding: 0;
    overflow: auto;
    color: #979797;
    line-height: 1;
}
::-webkit-scrollbar {
    display: none;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 9px;
}
::-webkit-scrollbar-thumb {
    background: #303F9F; 
    border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
    background: #303F9F; 
}
.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}
@media screen and (max-width: 40em) {
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}
.p-shadow-6 {
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12);
}
.p-float-label {
    margin-bottom: 17px;
    width:100% !important;
}
.p-datatable-tbody > tr > td {
    padding: 6px !important;
}
.p-paginator-bottom {
    padding: 0px !important;
}
.p-dialog-header {
    padding-bottom: 12px !important;    
}
.p-fieldset-legend {
    padding: 0.3rem !important;
    background: inherit !important;
    border: none !important;
}
.p-fieldset-content {
    padding-top: 6px !important;
}
.p-component {
    font-family: -apple-system, Roboto, Helvetica, Arial, sans-serif !important;
}
.p-fileupload-row {
    display: block !important
}
.p-fileupload-files {
    margin-top: -30px !important;
}
.p-fileupload-buttonbar {
    text-align: center !important;
}
.p-fileupload-buttonbar {
    padding: 0.3rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.required_input_error {
    border: 3px solid red !important;
}
.p-toast {
    width: 510px;
}
.p-button-label {
    text-align: left;
}
.p-selectable-row:hover {
    background: #FCFCFC !important;
}
.p-fileupload-content {
    min-height: 204px;
}
.p-progressbar {
    height: 9px;
}
.p-card-title {
    text-align: left;
}
.p-card-subtitle {
    text-align: left;
    margin-left: 15px;
}
.p-card-content {
    padding-bottom: 0px !important;
}
.p-card-body {
    padding-bottom:9px !important;
}
.tabview-header-icon i, .tabview-demo .tabview-header-icon span {
    vertical-align: middle;
}
.tabview-header-icon span {
    margin: 0 .5rem;
}
.p-tabview p {
    line-height: 1.5;
    margin: 0;
}
.padding-min {
    padding: 3px !important;
}
.padding-med {
    padding: 9px !important;
}
.padding-max {
    padding: 12px !important;
}
.p-inputtext {
    width: 100%;
    padding: 0.6rem !important;
    height: 38px;
}
.main-toolbar {
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
/*    width: 97%;
    margin: 0 auto; 
    margin-bottom: 9px; */
    border-radius: unset;
    background-image: -webkit-linear-gradient(left, #2764B6, #303BB5);
}
.field > label {
    font-weight: 600 !important;
    color: #000000 !important;
    font-size: 15px !important;
}
.field > div > label {
    font-weight: 600 !important;
    color: #000000 !important;
    font-size: 15px !important;
}
.field > div > label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.p-scrollpanel-content {
    overflow-x: hidden;
}
@media screen and (max-width: 480px) {
    .responsive-dialog-small-small {
        width: 81%;
    }
    .responsive-dialog-small {
        width: 81%;
    }
    .responsive-dialog-small-med {
        width: 84%;
    }
    .responsive-dialog-med {
        width: 90%;
    }
    .responsive-dialog-med-big {
        width: 95%;
    }
    .responsive-dialog-big {
        width: 99.5%;
    }
}
@media screen and (min-width: 800px) {
    .responsive-dialog-small-small {
        width: 60%;
    }
    .responsive-dialog-small {
        width: 63%;
    }
    .responsive-dialog-small-med {
        width: 80%;
    }
    .responsive-dialog-med {
        width: 80%;
    }
    .responsive-dialog-med-big {
        width: 85%;
    }
    .responsive-dialog-big {
        width: 90%;
    }
}
@media screen and (min-width: 1200px) {
    .responsive-dialog-small-small {
        width: 40%;
    }
    .responsive-dialog-small {
        width: 48%;
    }
    .responsive-dialog-small-med {
        width: 60%;
    }
    .responsive-dialog-med {
        width: 70%;
    }
    .responsive-dialog-med-big {
        width: 75%;
    }
    .responsive-dialog-big {
        width: 84%;
    }
}
@media screen and (min-width: 1400px) {
    .responsive-dialog-small-small {
        width: 20%;
    }
    .responsive-dialog-small {
        width: 34%;
    }
    .responsive-dialog-small-med {
        width: 36%;
    }
    .responsive-dialog-med {
        width: 51%;
    }
    .responsive-dialog-med-big {
        width: 60%;
    }
    .responsive-dialog-big {
        width: 80%;
    }
}
.p-dialog {
    background-color: #F4F4F7;
}
.p-dialog-header {
    background-color: #F4F4F7;
}
.p-dialog-content {
    background-color: #F4F4F7;
}
.text-bold {
    font-weight: 600;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
@media screen and (max-width: 1200px) {
    .hidden-small {
        display: none !important;
    }
}
.p-button-sm {
    margin-top: 0px important;
    height: 37px !important;
}
.p-dropdown-items-wrapper {
    max-height: 300px !important;
}
#select_screen .p-radiobutton-box {
    margin-top: -5px;
}
.p-dropdown {
    height: 38px !important;
}
.invoices_rfd_row {
    background-color: #00FFB3 !important;
}
.invoices_deleted_row {
    background-color: #FF7C00 !important;
}
.p-inputgroup-addon {
    height: 38px;
}
.align_left {
    text-align: left;
}
.align_right {
    text-align: right;
}
.align_center {
    text-align: center;
}
